.homeContainer {
    margin-bottom: 10rem;
}

.textContainer {
    max-width: 1000px;
    margin: 3rem auto;

    p {
        margin-top: 1.5rem;
    }
}

.tileContainer {
    display: flex;
    justify-content: space-evenly;
    max-width: 1000px;
    margin: auto;
    flex-wrap: wrap;

    .tile {
        min-width: 300px;
        margin-top: 2rem;
        margin-right: 2rem;
        padding: 0 0 1rem 0;

        .arrow {
            position: relative;
            left: 2px;
            top: 2px;
        }

        p {
            margin: 1rem 1rem 1.5rem;
        }

        span {
            margin-left: 1rem;
            color: rgb(141, 141, 141)
        }
    }
}