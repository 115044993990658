.textContainer {
    max-width: 1000px;
    margin: 3rem auto;

    p {
        margin-top: 1.5rem;
    }

    ul {
        margin: 1rem 3rem;
        list-style-type: circle;

        li {
            margin-bottom: 0.5rem;
        }
    }

    h1 {
        margin-bottom: 2rem;
    }

    h2 {
        margin-top: 2rem;
    }

    h3 {
        margin-top: 2rem;
    }

    .tab {
        max-width: 20rem;
    }
}

.graphs {
    margin: 2rem;
    display: flex;
    justify-content: center;
}