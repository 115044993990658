.contact {
    max-width: 800px;
    margin: 6rem auto;

    label {
        margin-top: 1rem;
    }
}

.response {
    display: inline;
    margin-left: 2rem;
    position: relative;
    top: 0.7rem;
}

.submitButton {
    margin-top: 2rem;
}

.responseMessage {
    margin-top: 0.5rem;
    color: rgb(141, 141, 141);
}
