.textContainer {
    max-width: 1000px;
    margin: 3rem auto;
}

.gridContainer {
    display: grid;
    grid-gap: 10px;
    margin: 50px auto;
    max-width: 1000px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 
        "i-pic i-desc i-desc"
        "d-desc d-desc d-pic";
    text-align: justify;
    justify-items: center;
}

.iPic {
    grid-area: i-pic;
    margin-bottom: 40px;
}

.dPic {
    grid-area: d-pic
}

.iDesc {
    grid-area: i-desc
}

.dDesc {
    grid-area: d-desc
}

.gridText {
    margin: 0 32px;

    p {
        margin-top: 0.4rem;
    }

    p:nth-child(1), p:nth-child(2) {
        color: rgba(141, 141, 141);
        letter-spacing: 0.5px;
    }
}

.gridPic {
    justify-content: center;
}

.profile {
    max-width: 100%;
    max-height: 100%; 
}

@media only screen and (max-width: 880px) {
    .profile {
        max-height: 600px;
        margin: auto;
    }

    .gridContainer {
        grid-template-areas: 
            "i-desc i-desc i-desc"
            "i-pic i-pic i-pic"
            "d-desc d-desc d-desc"
            "d-pic d-pic d-pic";
    }

    .gridText {
        margin: 0;
    }
}