.navButton {
    height: 100%;
    text-align: center;
    line-height: 3rem;
    text-decoration: none;
    color: black;
    padding: 0 10px;
}

.navButton:hover {
    background-color: rgba(141, 141, 141, 0.12);
}

.navActive {
    border-top: 1.5px solid #161616 ;
    padding-bottom: 1px;
    line-height: calc(3rem - 3px);
}

.langPanel {
    padding-top: 20px;
}

.langButton {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 17px;
    border: none;
    background-color: #f4f4f4;
}

.langActive {
    background-color: #e8e8e8;
}

.langFlag{
    width: 30px !important;
    height: 20px !important;
}

.langText {
    color: #525252;
    font-weight: 600;
    font-size: 16px;
}

.langButton:hover {
    background-color: rgba(141, 141, 141, 0.12);
    color: #161616;
}